import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import Login from "../../components/auth/";
import queryString from "query-string";
import { Location } from "@reach/router";

const Index = () => {
  const authState = useSelector((state) => state.auth);
  useEffect(() => {
    if (
      authState.isAuthenticated === true &&
      authState.hasCheckedAuth === true
    ) {
      navigate("/");
    }
  }, [authState]);

  function returnForm(location, navigate) {
    try {
      const search = location.search ? queryString.parse(location.search) : {};
      return <Login type={"new"} query={search} />;
    } catch (error) {
      return <div></div>;
    }
  }

  return (
    <div>
      <Location>
        {({ location, navigate }) => returnForm(location, navigate)}
      </Location>
    </div>
  );
};

export default Index;
